<script>
import MusicItem from '@/components/bolck/MusicItem.vue'
import BlockItem from '@/components/bolck/BlockItem.vue'
import ContextMenu from '@/components/contextMenu/contextMenu.vue'
import Header from '@/components/layout/Header.vue'
import { findSingerById } from '@/api/singer'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { useContextMenu } from '@/utils/useContextMenu'
import ActionBar from '@/components/layout/ActionBar.vue'
import { collect, unfollow } from '@/api/collect'
import ReplyInput from '@/components/bolck/replyInput.vue'
import Reply from '@/components/bolck/reply.vue'
import { findSingerReplyById, insert } from '@/api/reply'

export default {
  name: 'singerDetail',
  computed: {
    ...mapGetters('collect', ['getUserMusicForm', 'isSingerCollect']),
    //  获取路由 歌手id
    getSingerId () {
      return +this.$route.params.id
    },
    isLogin () {
      return this.$store.getters.token
    }
  },
  components: {
    Reply,
    ReplyInput,
    ActionBar,
    MusicItem,
    BlockItem,
    ContextMenu,
    Header
  },
  beforeDestroy () {
    // 关闭滚动事件
    window.removeEventListener('scroll', this.handleScroll)
  },
  created () {
    // 注册滚动事件
    window.addEventListener('scroll', this.handleScroll)
    //
    this.getSinger()
    this.getReply()
  },
  mounted () {
    setTimeout(() => {
      this.loading = false
    }, 1000)
    // 将会话中的歌单数据赋值给菜单
    this.menu[0].menu = this.getUserMusicForm
  },
  methods: {
    // 点击固定输入框时，清除选中输入框
    toClear () {
      this.clear()
      this.setActiveReplyId(null)
    },
    // 获取评论
    async getReply () {
      await findSingerReplyById(this.getSingerId).then(res => {
        if (res.data == null) return
        this.total = res.data.total
        this.reply = res.data.page
      })
    },
    async submit () {
      await insert(this.$store.state.reply.reply, null, null, this.getSingerId, null)
        .then(res => {
          this.getReply()
          this.clear()
        })
    },
    ...mapActions('reply', ['clear', 'setActiveReplyId']),
    ...mapActions('collect', ['getCollectForm']),
    ...mapMutations('playlist', ['pushPlayList', 'setPlayList']),
    handleScroll () {
      console.log('handleScroll')
      // 获取滚动距离
      const scrollTop = window.scrollX || document.documentElement.scrollTop || document.body.scrollTop
      const maxScroll = 100 // 设置滚动的最大位置
      const opacity = Math.min(1, scrollTop / maxScroll) // 计算透明度，最大不超过1
      // 设置颜色和透明度
      console.log(opacity)
      this.$refs.background.style.backgroundColor = this.singer.color
      this.$refs.background.style.opacity = opacity.toString()
    },
    // 根据id查询歌手
    async getSinger () {
      await findSingerById(this.getSingerId).then(res => {
        if (res.data == null) return
        this.singer = res.data
        this.musicList = res.data.musicList
        this.albumList = res.data.albumList
      })
    },
    // 右键选中的选项
    pickMenu (e) {
      useContextMenu(e, this.pickMusicItem, this.getSingerId)
    },
    // 提交播放数据
    submitPlay () {
      this.setPlayList(this.musicList)
    },
    // 关注
    async attention () {
      if (this.isLogin) {
        await collect(this.getSingerId, null, null).then(res => {
          this.getCollectForm()
          this.isSingerCollect(this.getSingerId)
        })
      }
    },
    // 取消关注
    async unfollow () {
      if (this.isLogin) {
        await unfollow(this.getSingerId, null, null).then(res => {
          this.getCollectForm()
          this.isSingerCollect(this.getSingerId)
        })
      }
    }
  },
  data () {
    return {
      // 评论条数
      total: '',
      // 评论
      reply: '',
      menu: [
        {
          id: 1,
          label: '加入歌单',
          icon: 'el-icon-folder-checked',
          menu: []
        },
        { id: 2, label: '加入队列', icon: 'el-icon-menu' },
        { id: 5, label: '分享给好友', icon: 'el-icon-info' }
      ],
      isExist: '',
      // 歌手信息
      singer: {},
      // 歌手
      musicList: [],
      // 专辑
      albumList: [],
      // 控制音乐渲染数量
      controlMusicNumber: 5,
      // 加载
      loading: true,
      // 选中的歌曲
      pickMusicItem: {},
      // MusicItem组件点击省略号时间
      position: {},
      isReply: true
    }
  }
}
</script>

<template>
  <div class="detail" v-loading="loading" v-title data-title="歌手详情">
    <Header :color="singer.color">
      <p>{{singer.name}}</p>
    </Header>
    <div class="main">
      <div class="background">
        <div class="public">
          <el-image
            ref="image"
            class="background-img"
            :src="singer.image"
            alt="" />
<!--          <img class="background-img" crossorigin="anonymous" alt="" ref="image" :src="this.singer.image  + '?time=' + new Date().valueOf()"/>-->
        </div>
        <div class="background-black public" ref="background"></div>
        <div class="cont-info">
          <span></span>
          <span>
            <i class="name">{{singer.name}}</i>
          </span>
          <span class="count">每月有 1,697,783 名听众</span>
        </div>
      </div>
      <div class="content">
<!--        <div class="cont-info">-->
<!--          <span></span>-->
<!--          <span>-->
<!--            <i class="name">{{singer.name}}</i>-->
<!--          </span>-->
<!--          <span class="count">每月有 1,697,783 名听众</span>-->
<!--        </div>-->
        <div class="cont-center">
          <div class="background-active"
            :style="{'background-color': singer.color}">
          </div>
          <div class="cont-works">
            <ActionBar
              @submitPlay="submitPlay"
              @attention="attention"
              @unfollow="unfollow"
              :is-exist="this.isSingerCollect(this.getSingerId)"
              :isReply.sync="isReply"
            />
            <transition name="component-fade" mode="out-in">
              <div v-if="isReply" style="min-height: 300px; display: flex; flex-wrap: wrap; flex-direction: column; margin: 0 20px 0 20px;">
                <div v-if="musicList.length > 0" class="cont-hot-music">
                  <h2 class="title">热门</h2>
                  <div class="target">
                    <ContextMenu
                      :menu="menu"
                      :position="position"
                      @select-menu="pickMenu"
                    >
                      <MusicItem
                        v-for="(item,index) in musicList.slice(0, this.controlMusicNumber)"
                        @select-music="pickMusicItem = $event"
                        @position="position = $event"
                        :key="item.id"
                        :music="item"
                        :index="index+1"
                      />
                    </ContextMenu>
                  </div>
                  <div class="more" >
                    <span v-if="controlMusicNumber === 5" @click="controlMusicNumber = 10">查看更多</span>
                    <span v-else @click="controlMusicNumber = 5">收起</span>
                  </div>
                </div>
                <div v-if="albumList.length > 0" class="cont-album" style="padding-bottom: 10px">
                  <h2 class="title">唱片专辑</h2>
                  <div class="target">
                    <BlockItem v-for="item in albumList" :key="item.id" :album="item"></BlockItem>
                  </div>
                </div>
                <div class="cont-introduce">
                </div>
              </div>
            </transition>
            <transition name="component-fade" mode="out-in">
              <div style="min-height: 300px;" v-if="!isReply" class="replyView">
                <ul class="nav-bar">
                  <li class="nav-title">
                    <span class="title-text">评论</span>
                    <span class="total-reply">{{total}}</span>
                  </li>
                  <li></li>
                </ul>
                <ReplyInput @sub="submit" @focus="toClear"></ReplyInput>
                <Reply v-for="item in reply" :key="item.replyId" :item="item" @sub="submit"></Reply>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.title{
  margin-bottom: 10px;
}
.detail {
  position: relative;
  .main {
    .background {
      height: 40vh;
      position: relative;
      width: 100%;
      .public {
        height: 40vh;
        width: 100%;
        position: absolute;
        top: 0;
        ::v-deep .background-img {
          height: 100%;
          width: 100%;
          img {
            height: 100%;
            width: 100%;
            z-index: -5;
            object-position: 50% 15%;
            object-fit: cover;
          }
        }
      }
      .background-black {
        //background: linear-gradient(transparent 0, rgba(0, 0, 0, .5) 100%);
      }
      .cont-info {
        position: absolute;
        bottom: 1rem;
        display: flex;
        flex-direction: column;
        color: #ffffff;
        margin: 0 20px;
        z-index: 100;
        span {
          font-size: 2rem;
          .name {
            color: #ffffff;
            z-index: -4;
            font-size: 10vh;
          }
        }
        .count{
          margin-left: 0.8vw;
          font-size: 1rem;
        }
      }
    }

    .content {
      min-height: calc(100vh - 40vh - 40px);
      //height: calc(100vh - 40vh);
      //.cont-info {
      //  position: absolute;
      //  display: flex;
      //  top: 2rem;
      //  flex-direction: column;
      //  color: #ffffff;
      //  overflow: hidden;
      //  margin: 5vh 20px 0;
      //  z-index: 0;
      //  span {
      //    font-size: 2vh;
      //    .name {
      //      color: #ffffff;
      //      z-index: -4;
      //      font-size: 7rem;
      //    }
      //  }
      //  .count{
      //    font-size: 1rem;
      //  }
      //}

      .cont-center {
        width: 100%;
        position: relative;
        top: 0;
        .background-active {
          //background: linear-gradient(transparent 0, rgba(0, 0, 0, .5) 100%);
          //background-image: linear-gradient(rgba(198, 197, 197, 0.4) 40%, #ffffff 100%);
          background-image: linear-gradient(rgba(198, 197, 197, 0) 40%, #ffffff 100%);
          height: 40vh;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 2;
        }
        .cont-works{
          position: relative;
          top: 1vh;
          z-index: 100;
          .cont-hot-music{
            position: relative;
            width: 70rem;
            margin-bottom: 20px;
            .dark{
              color: black;
            }
            h2{
              color: #ffffff;
            }
            .target{
              width: 100%
            }
            .more{
              z-index: 10;
              display: inline-block;
              color: #696969;
              margin-top: 1.5rem;
              &:hover{
                color: black;
              }
            }
          }
          .cont-introduce{
            width: 20rem;
            .introduce-target{
              .el-card{
                width: 450px;
                height: 300px;
                z-index: 5;
                .img{
                  width: 100%;
                  height: 100%;
                  .i{
                    position: relative;
                    z-index: 100;
                  }
                }
              }
            }
            .dark{
              color: black;
            }
            h2{
              white-space:nowrap;
              color: #ffffff;
            }
          }
          .replyView{
            width: 100%;
            height: 100%;
            //height: 30rem;
            padding: 0 40px 0 40px;
            position: relative;
            top: -80px;
            margin-top: 5rem;
            z-index: -1;
            .nav-bar{
              margin-bottom: 10px;
              .nav-title{
                font-size: 20px;
                .title-text{
                  color: #18191C;
                  margin-right: 5px;
                }
                .total-reply{
                  height: 100%;
                  font-size: 13px;
                }
              }
            }
            .reply-tool{
              padding: 20px 0;
              margin-bottom: 10px;
              display: flex;
              .input{
                .btn-box{
                  display: flex;
                  margin-top: 10px;
                  height: 32px;
                  transition: all .3s ease-in-out;
                  .btn{
                    margin-left: auto;
                  }
                }
                .hide{
                  margin-top: -30px;
                  height: 0;
                  overflow: hidden;
                  transition: all .2s ease-in-out;
                }
              }
            }
          }
        }
      }
    }
  }
}
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .2s ease;
}
.component-fade-enter, .component-fade-leave-to{
  opacity: 0;
}
</style>
